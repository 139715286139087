const environment = {
  production: {
    APP_API_URL: 'https://borsatnaiq-app.com/api',
    APP_PATH: '/',
  },
  development: {
    APP_API_URL: 'https://borsatnaiq-app.com/api',
    APP_PATH: '/',
  },
};

module.exports = environment;
