// axios
import axios from 'axios'
import Vue from 'vue'
import auth from '@/store/auth'
import toast from '@/store/toast'
import store from '@/store/index'
import { i18n } from '@/plugins/i18n'
// eslint-disable-next-line import/no-absolute-path,import/no-unresolved

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // timeout: 1000,
  headers: {
    Accept: 'application/json',
  },
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('token')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) {
      config.params = config.params || {};
      config.params.api_token = accessToken;
      console.log(accessToken)
    }
    config.headers['Accept-Language'] = i18n.locale

    // console.log(i18n.locale)

    return config
  },
  error => {
    Promise.reject(error)
  },
);

axiosIns.interceptors.response.use(
  response => {
    if (response.data.entering_level != null) {
      store.commit('SET_ENTERING_LEVEL', response.data.entering_level)
    }

    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      if (auth.state.loggedIn) {
        auth.dispatch('logout')
      } else {
        toast.dispatch('error', 'معلومات الدخول غير صحيحه')
      }
    } else {
      toast.dispatch('error', error.response ? error.response.data.error : error.message)
    }
    Promise.reject(error)
    throw error;
  },
);

Vue.prototype.$http = axiosIns

export default axiosIns
