import {
  mdiAccountGroupOutline,
  mdiHomeOutline,
  mdiNewspaper,
  mdiCashMultiple, mdiAdvertisements,
} from '@mdi/js'

export default [
  {
    subheader: 'menu.home1',
  },
  {
    title: 'menu.home',
    icon: mdiHomeOutline,
    color: 'info',
    to: 'home',
  },
  {
    subheader: 'menu.navigation',
  },

  {
    title: 'menu.subjects',
    icon: mdiNewspaper,
    color: 'error',
    children: [
      {
        title: 'menu.news',
        to: 'news.news',
        color: 'primary',
      },
      {
        title: 'menu.ports',
        to: 'news.ports',
        color: 'primary',
      },
    ],
  },

  {
    title: 'menu.advertisements',
    icon: mdiAdvertisements,
    color: 'accent',
    to: 'advertisements.index',
  },

  {
    title: 'menu.currencies',
    icon: mdiCashMultiple,
    color: 'primary',
    children: [
      {
        title: 'menu.market',
        to: 'currencies.market',
        color: 'primary',
      },
      {
        title: 'menu.borsa',
        to: 'currencies.borsa',
        color: 'primary',
      },
      {
        title: 'menu.gold',
        to: 'currencies.gold',
        color: 'primary',
      },
      {
        title: 'menu.all_currencies',
        to: 'currencies.all_currencies',
        color: 'primary',
      },
    ],
  },

  {
    title: 'menu.users',
    icon: mdiAccountGroupOutline,
    color: 'warning',
    children: [
      {
        title: 'menu.view_users',
        to: 'users.index',
        color: 'primary',
      },
      {
        title: 'menu.view_managers',
        to: 'managers.index',
        color: 'error',
      },
    ],
  },

  // {
  //   title: 'menu.site.settings',
  //   to: 'site.settings',
  //   color: 'error',
  //   class: 'spin',
  //   icon: mdiCog,
  // },

]
